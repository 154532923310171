body {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#root,
#root section {
  height: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-layout-header {
  background-color: #fafafa;
  height: 115px;
  padding: 0 2vw;
}
.ant-layout-header .logo {
  /* margin: 16px 28px 16px 0; */
  float: left;
}
.ant-layout-header .ant-menu {
  border: none;
  background-color: unset;
}
.ant-layout-header h3 {
  line-height: normal;
  margin: 0;
  text-align: center;
  color: #ff4757;
  cursor: pointer;
}
.ant-layout {
  background-color: white;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}
h1 {
  font-size: 24px;
  font-weight: bold;
}
.bar-title {
  border-inline-start: 5px solid #feda45;
  padding-inline-start: 10px;
}
.ant-form-item-label label,
.ant-form {
  color: black;
}
.ant-input,
.ant-input-affix-wrapper {
  border-color: #e4eaf1;
  border-radius: 5px;
  /* padding: 12px 20px; */
}
.ant-btn-big {
  padding: 12px 60px;
  height: unset;
  border-radius: 5px;
}
.ant-menu.ant-menu-dark {
  background-color: black;
  padding: 40px 0px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: black;
}
.ant-menu.ant-menu-dark.ant-menu-submenu {
  background-color: black;
  padding: 0px;
}
.ant-menu.ant-menu-dark.ant-menu-inline {
  padding: 40px 30px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: unset;
  font-weight: bold;
}
.ant-menu-dark .ant-menu-item span:last-child {
  transition: border 300ms;
  border-left: 3px solid black;
  padding-left: 6px;
}
.ant-menu-dark .ant-menu-item-selected span:last-child {
  border-inline-start: 3px solid #feda45;
  padding-inline-start: 6px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  background-color: #feda45;
  height: 4px;
}
.ant-tabs-tab-btn {
  font-size: 16px;
  color: black;
  font-weight: 500;
}
.ant-tabs-tab-btn:hover {
  color: #feda45;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
  font-weight: bold;
}
.ant-btn-link {
  color: black;
}
.ant-form-rtl .ant-form-item-label {
  text-align: unset;
}
.recharts-responsive-container {
  height: 30vh !important;
}

aside.ant-layout-sider {
  overflow: auto;
}
aside.ant-layout-sider::-webkit-scrollbar {
  width: 10px;
}
/* Track */
aside.ant-layout-sider::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
aside.ant-layout-sider::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
aside.ant-layout-sider::-webkit-scrollbar-thumb:hover {
  background: #555;
}
